import React,{ useRef, useState, useEffect} from "react";
import JSZip from "jszip";
import "./style.css";

const OpusRecording = ({ 
  setIsLoading,
  audioChunks,
  setAudioChunks,
  setAudioPlaying,
  setAudioUrl,
  isRecording,
  all_messages,
  set_all_message, 
  user,
  setUser,
  isLoadingSpin, setIsLoadingSpin,
  setIsOpen, isOpen,
  emailChangedOnAudio, setmailChangedOnAudio,
  fastMessage,
  setShowFast,
  showFastQuestion, setShowFastQuestion
}) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const wsRef = useRef(null);
  const sendTimestampRef = useRef(null); // Référence pour stocker l'horodatage d'envoi
  const [hide2, setHide2] = useState(false) //Pour masquer le micro et mettre Oui et NOn
  const lastMessageFromUser = useRef("")
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [creneauIsAvailable, setCreneauIsAvailable] = useState(false);
  const [disabledMicro, setDisabledMicro] = useState(true)
  const [conditionChoix, setConditionChoix] = useState(true)
  const first = useRef(0)

  useEffect(() => {
    if(!wsRef.current){
      wsRef.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL + '/ws_predict_audio'+'?id='+id);
      wsRef.current.onopen = () => {
        console.log('WebSocket connection opened');
        setShowFast(true)
      };

      wsRef.current.onmessage = (event) => {
        setIsLoadingSpin(false)
        const receiveTimestamp = Date.now(); // Capture le temps de réception
        if (sendTimestampRef.current) {
          const processingTime = receiveTimestamp - sendTimestampRef.current;
          console.log("Temps de traitement:", processingTime, "ms");
        }

        // Gérer les messages entrants du serveur
        if (event.data instanceof Blob) {
          console.log("get blob",event.data)
           handleBlob(event.data);
          setAudioPlaying(true);
          return;
        } else {
          console.log("event.data",event.data)
          let sohait = [
            "Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?",
            "Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ?",
            "Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ ?",
            "Désirez-vous que je vous présente le premier enjeu : les réflexions patrimoniales avant le départ ?",
            "Voulez-vous que je vous expose le premier enjeu : les réflexions patrimoniales avant le départ ?",
            "Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ ?",
            "Préférez-vous que je vous détaille le premier enjeu : les réflexions patrimoniales avant le départ ?",
            " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?",
            " Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ?",
            " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ ?",
            " Désirez-vous que je vous présente le premier enjeu : les réflexions patrimoniales avant le départ ?",
            " Voulez-vous que je vous expose le premier enjeu : les réflexions patrimoniales avant le départ ?",
            " Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ ?",
            " Préférez-vous que je vous détaille le premier enjeu : les réflexions patrimoniales avant le départ ?",
            "Souhaitez-vous planifier une session privée de 30 minutes et recevoir l’enjeu fiscal de l’exit-tax détaillé? ",
            " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?",
            " Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ?",
            " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?",
            " Désirez-vous que je vous présente le premier enjeu : les réflexions patrimoniales avant le départ?",
            " Voulez-vous que je vous expose le premier enjeu : les réflexions patrimoniales avant le départ?",
            " Souhaitez-vous une explication du premier enjeu : les réflexions patrimoniales avant le départ?",
            " Préférez-vous que je vous détaille le premier enjeu : les réflexions patrimoniales avant le départ?",
            " Souhaitez-vous que je vous explique le premier enjeu : les réflexions patrimoniales avant le départ?‎",
            " Souhaitez-vous que je vous explique le premier enjeu : la prise de résidence en Italie?‎     ‎"
          ];
          let souhaiteavg = ["Souhaitez-vous explorer d'autres implications fiscales?", "Souhaitez-vous explorer d'autres implications fiscales?‎     ‎"," Souhaitez-vous explorer d'autres implications fiscales?", " Souhaitez-vous explorer d'autres implications fiscales ?", " Souhaitez-vous explorer d'autres implications fiscales?‎     ‎"," Souhaitez-vous explorer d'autres implications fiscales?‎     ‎"]
          //CHECK BOUTON OUI NON
          try{

            let all_message_split = event.data.split("\n");
            let last_element =
              all_message_split[all_message_split.length - 1];
            if (sohait.includes(last_element)) {
              setHide2(true)
            }
            if (souhaiteavg.includes(last_element)){
              setConditionChoix(false)
              setHide2(true)
            }
          }catch(error){
            console.log(error)
          }
          //FIN CHECK BOUTON OUI NON
          

          //ADD ASSISTANT RESPONSE
          try{
            if(typeof(event.data) =="string"){
              let m = checkCreneauDisponible(event.data);
              if(m!=event.data){
                set_all_message(prevText => [...prevText, m]);
                console.log(m)
                return;
              }
              if (typeof m === "string" && m.includes("[calendly]")) {
                m = m.replace(
                  "[calendly]",
                  `<button  id="btnCalendly" className="mt-2 bg-black text-white rounded-lg px-2 py-1 border border-black hover:bg-white hover:text-black transition duration-300">Prendre un RDV</button>`
                );
                // Configurer le MutationObserver pour observer l'ajout de nouveaux éléments au body
                observer.observe(document.body, {
                  childList: true,
                  subtree: true, // Inclure les sous-arbres pour capter les éléments ajoutés en profondeur
                });
              }
              if (typeof m === "string" && (m.includes("[PPTX]") || m.includes("PPTP") || m.includes("PPT-P") || m.includes("PPT-PPT"))) {
                let pptx =  `
                <div className="-mt-1 mb-2 bg-slate-100 rounded-md p-2 flex flex-row">
                   <div> <svg height="30px" width="30px" version="1.1" id="Capa_1" 
                    viewBox="0 0 56 56" xmlSpace="preserve">
                    <g>
                    <path style="fill:#E9E9E0;" d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
                    c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
                    <polygon style="fill:#D9D7CA;" points="37.5,0.151 37.5,12 49.349,12 	"/>
                    <path style="fill:#CC4B4C;" d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
                    c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
                    c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
                    c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
                    c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
                    c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
                    c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
                    c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
                    M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
                    c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
                    c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
                    M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"/>
                    <path style="fill:#CC4B4C;" d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"/>
                    <g>
                    <path style="fill:#FFFFFF;" d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
                    c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
                    c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
                    s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
                    c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
                    c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
                    c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"/>
                    <path style="fill:#FFFFFF;" d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
                    s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
                    c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
                    M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
                    c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"/>
                    <path style="fill:#FFFFFF;" d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"/>
                    </g>
                    </g>
                    </svg></div><div className="flex-auto pt-2 w-max text-xs text-center">${user.name == "" || user.name == null ? "Première partie de votre étude...pdf" : `${user.name} - Première partie....pdf`} </div> </div>`
                m = m.replace(
                  "[PPTX]",pptx);
                  m = m.replace(
                    "[PPTP]",pptx
                    );
                    m = m.replace(
                      "[PPT-P]",pptx
                      );
                      m = m.replace(
                        "[PPT-PPT]",pptx
                        );
              }
              if (typeof m === "string" && m.includes("[QUESTION]")) {
                setConditionChoix(false)
                setHide2(true)
                m=m.replace("[QUESTION]","")
              }
              set_all_message((prevMessages) => {
                //ADD USER PROMPT
                const isJSON = (str) => {
                  const jsonRegex = /^[\[\{](.|\n)*[\]\}]$/;
                  return jsonRegex.test(str);
                };
                
                if (isJSON(event.data)) {
                  try {
                    let data = JSON.parse(event.data);
                    console.log(data);
                    console.log(typeof data);
                    console.log(data.content);
                    
                    if ("role" in data && "content" in data && data.role == "user") {
                      lastMessageFromUser.current = data.content;
                      return [...prevMessages, data.content + "‎     ‎"];
                    }
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  console.log("Not a valid JSON format");
                }
                
                //FIN ADD USER PROMPT
                if (prevMessages && prevMessages.length > 0) {

                  const lastMessage = prevMessages[prevMessages.length - 1];
                  let condition = false
                  try{
                    condition = lastMessage.startsWith("{")
                  }catch{
                    condition = false
                  }
                  if(condition){
                    console.log("user")
                  }else{
                  if (
                    typeof lastMessage == "string" &&
                    lastMessage.endsWith("‎     ‎")
                  ) {
                    //alert("ato")
                    return [...prevMessages, m];
                  } else if (lastMessage === lastMessageFromUser.current) {
                    //alert("ato2")
                    return [...prevMessages, m]; // Ajouter event.data comme nouveau message
                  } else {
                    if (typeof lastMessage == "string") {
                      //alert("ato3")
                      return [...prevMessages.slice(0, -1), lastMessage + m];
                    } else {
                      //alert("ato4")
                      return [...prevMessages, m];
                    }
                  }
                }
                } else {
                  //alert("ato5")
                  return [m];
                }
              });
            }
          }catch{

          }
          //FIN ADD ASSISTANT RESPONSE
        }
      };
    }
    
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      first.current= fastMessage!="Je souhaite m'installer en Italie." && fastMessage!="" ? first.current + 1 : first.current
      const additionalMessage = first.current==1 ? "Non, j'ai une question. " : ""
      wsRef.current.send(additionalMessage+fastMessage);
      set_all_message((prevMessages) => [...prevMessages, fastMessage+"‎     ‎"]);
      
      sendTimestampRef.current= Date.now()
    }
    
  }, [fastMessage]);

  function generateId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 10;
    let result = '';
  
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
  
    return result;
  }

  const handleSubmitOui = (event) =>{
    if (event) {
        event.preventDefault();
      }
     console.log("Oui")
    
    if (
        wsRef.current &&
        wsRef.current.readyState === WebSocket.OPEN
      ) {
        handleBlob("null")
        let message = "Oui, je veux bien."
        wsRef.current.send(message);
        set_all_message(prevText => [...prevText, "Oui‎     ‎"]);
        setHide2(false)
      }
    else{
      wsRef.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL + '/ws_predict_audio'+'?id='+id);
      if (
        wsRef.current &&
        wsRef.current.readyState === WebSocket.OPEN
      ) {
        handleBlob("null")
        let message = "Oui, je veux bien."
        wsRef.current.send(message);
        set_all_message(prevText => [...prevText, "Oui‎     ‎"]);
        setHide2(false)
      }
    }
  }

  const handleSubmitOui2 = (event) =>{
    if (event) {
        event.preventDefault();
      }   
    if (
      wsRef.current &&
      wsRef.current.readyState === WebSocket.OPEN
      ) {
        setShowFastQuestion(true)
        setHide2(false)
      }
    else{
      wsRef.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL + '/ws_predict_audio'+'?id='+id);
      if (
        wsRef.current &&
        wsRef.current.readyState === WebSocket.OPEN
      ){
        setShowFastQuestion(true)
        setHide2(false)
      }
    }
  }

  const handleSubmitNon = (event) =>{
    if (event) {
        event.preventDefault();
      }
      console.log("NOn")
    if (
        wsRef.current &&
        wsRef.current.readyState === WebSocket.OPEN
      ) {
        handleBlob("null")
        setShowFastQuestion(true)
        setHide2(false)
      }else{
        wsRef.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL + '/ws_predict_audio'+'?id='+id);
        if (
          wsRef.current &&
          wsRef.current.readyState === WebSocket.OPEN
        ) {
          handleBlob("null")
          setShowFastQuestion(true)
          setHide2(false)
        }
      }
    
  }
  const handleSubmitNon2 = (event) =>{
    if (event) {
        event.preventDefault();
      }
      console.log("NOn")
    if (
      wsRef.current &&
      wsRef.current.readyState === WebSocket.OPEN
      ) {
    
        const messageData = {
          user_id: id,
          prompt: "Non, je passe.",
        };
        wsRef.current.send(JSON.stringify(messageData));
        set_all_message((prevMessages) => [...prevMessages, "Non‎     ‎"]);
       }
       setHide2(false)
  }
  const [id, setId] = useState(generateId);
  const startRecording = async () => {
    try {
      
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      handleBlob("null")

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = handleDataAvailable;

      mediaRecorder.onstop = async () => {
        setIsLoading(true);
        setIsLoading(false);
        setAudioChunks([]); // Vider les chunks après la création du blob final
      };

      mediaRecorder.start();
      setMediaRecorder(mediaRecorder);

    } catch (error) {
      console.error('Erreur lors de l\'accès à l\'appareil audio:', error);
    }
  };

  const stopRecording = async () => {
    setIsLoading(true);
    isRecording.current = false;
    if (mediaRecorder) {
      mediaRecorder.stop();
    } else {
      console.error('Erreur lors de l\'arrêt de l\'enregistrement:');
    }
  };

  const manageRecording = async () => {
    isRecording.current = !isRecording.current;
    console.log("isRecording2", isRecording.current);
    if (isRecording.current) {
      await startRecording();
    } else {
      await stopRecording();
    }
  };

  const handleDataAvailable = async (event) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      handleBlob("null")
      const zip = new JSZip();
      zip.file("audio_chunk.opus", event.data);

      sendTimestampRef.current = Date.now(); // Capture le temps d'envoi

      const compressedData = await zip.generateAsync({ type: "blob" });
      wsRef.current.send(compressedData);

      // Simuler une attente pour observer l'envoi asynchrone
      setTimeout(() => {
        let sendTimestampRefEND = Date.now();
        console.log("Temps d'envoi (approximatif)", sendTimestampRefEND - sendTimestampRef.current, "ms");
      }, 0);
    }
    setAudioChunks((prevChunks) => [...prevChunks, event.data]);
  };

  const handleBlob =  (blob) => {
    setAudioUrl(blob);
  };

  //Check_data
  // Fonction qui vérifie qu'il y a des créneaux disponibles dans la réponse
  const checkCreneauDisponible = (llm_pred) => {
    const mot_cle = "Voici quelques propositions de date";

    const llm_response_split = llm_pred.split(",");

    const createButton = (word, onClick, disabled) =>
      React.createElement(
        "button",
        {
          className: disabled
            ? "creneau-btn-deactive opacity-50 cursor-not-allowed"
            : "creneau-btn bg-black text-white rounded-lg px-1 py-1 border border-black hover:bg-white hover:text-black transition duration-300 my-1",
          key: word,
          onClick: () => {
            setButtonsDisabled(true);
            onClick(convertFrenchDateToEnglishFormat(word));
          },
          disabled: disabled, // Désactive le bouton
        },
        word
      );

    const convertDateToFrenchFormat = (dateString) => {
      console.log("convertion ok");
      const monthsEn = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const monthsFr = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const [day, month, year, atTime, ...timeArray] =
        dateString.split(/[\s,]+/);
      console.log("month", month);
      const time = timeArray.join(" ");
      const monthIndex = monthsEn.indexOf(month);
      console.log("monthIndex", monthIndex);

      if (monthIndex !== -1) {
        const monthFr = monthsFr[monthIndex];
        console.log(`${day} ${monthFr} ${year} à ${time}`);
        return `${day} ${monthFr} ${year} à ${time}`;
      }
      console.log("dateString", dateString);
      // Retourne la date d'origine si le mois n'est pas trouvé
      return dateString;
    };

    const convertFrenchDateToEnglishFormat = (dateString) => {
      const monthsEn = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const monthsFr = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const [day, month, year, atTime, ...timeArray] =
        dateString.split(/[\s,]+/);
      var times = timeArray.join(" ");
      var time_temp = times.split("h");
      const time = time_temp.join(":");
      const monthIndex = monthsFr.indexOf(month);

      if (monthIndex !== -1) {
        const monthEn = monthsEn[monthIndex];
        return `${day} ${monthEn} ${year} at ${time}`;
      }

      // Retourne la date d'origine si le mois n'est pas trouvé
      return dateString;
    };

    const extractWordsStartingWith = (input, separator) => {
      const words = input.split(separator);
      words.shift(); // effacer le premier élément

      const convertedDates = words.filter((word) => word.trim() !== ""); // Vérifie que le mot n'est pas vide après avoir été trimé
      console.log("convertedDates", convertedDates);
      const convertedDatesFiltred = convertedDates.map((dateString) =>
        convertDateToFrenchFormat(dateString)
      );
      console.log("convertedDatesFiltred", convertedDatesFiltred);
      return convertedDatesFiltred;
    };

    const processInput = (input, keyword, onButtonClick) => {
      if (input.includes(keyword)) {
        const extracted_datetime = extractWordsStartingWith(input, "$");
        console.log("extracted_datetime", extracted_datetime);
        const extracted_email = extractWordsStartingWith(
          llm_response_split[1],
          "_$"
        );

        if (user.email === "") {
          //setEmailUserFromLLM(extracted_email[0]);
          setUser(prevState => ({
            ...prevState,      // Copie tous les autres attributs
            email: extracted_email[0]      // Met à jour uniquement l'attribut 'email'
          }));
        }

        if (extracted_datetime.length > 0) {
          return React.createElement(
            "div",
            { className: "creneau-btn-container" },
            React.createElement(
              "p",
              { style: { marginBottom: "10px" } },
              `${keyword} : `
            ),
            React.createElement(
              "div",
              { style: { display: "flex", flex: 1, flexDirection: "column" } },
              extracted_datetime.map((word) =>
                createButton(word, onButtonClick, buttonsDisabled)
              )
            )
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const buttonClickHandler = (word) => {
      setIsLoadingSpin(true);
      const buttons = document.getElementsByClassName("creneau-btn");

      // Parcourir tous les éléments et les désactiver
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true; // Désactiver le bouton
        buttons[i].classList.add("disabled"); // Ajouter une classe CSS pour un style visuel
      }

      if (creneauIsAvailable) {
      } else {
        //setRequestLoaderState(true);
        let final_text = `Puis-je prendre rendez-vous pour le ${word}. Avec ${user.email}`;
        console.log(final_text);
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.send(
            JSON.stringify({
              date: final_text,
              full_name: user.name,
              mail: user.email,
            })
          );
          setCreneauIsAvailable(true);
        }
      }
    };

    // Appel des fonctions
    const result = processInput(
      llm_response_split[0],
      mot_cle,
      buttonClickHandler
    );

    return !result ? llm_pred : result;
  };
  function addClickListenerIfElementExists() {
    const element = document.getElementById("btnCalendly");
    if (element) {
      element.addEventListener("click", function () {
        setIsLoadingSpin(true);
        element.disabled = true;
        //set_all_message(prevText => [...prevText, "‎     ‎"]);
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          let usera = user
          
          if (usera) {
            if (user.email != null) {
              wsRef.current.send(JSON.stringify(user));
            } else {
              setIsOpen(!isOpen)
            }
            
          }
        }
      });
    }
  }

  useEffect(() => {
    if(emailChangedOnAudio){
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ name: user.name, email: user.email }));
      }
    }
  }, [emailChangedOnAudio]);

  // Création d'un MutationObserver pour surveiller les changements dans le DOM
  const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        // Vérifier si l'élément avec l'ID est maintenant présent dans le DOM
        if (document.getElementById("btnCalendly")) {
          // Une fois l'élément trouvé, on ajoute l'event listener
          addClickListenerIfElementExists();
          // On arrête l'observation une fois l'élément trouvé
          observer.disconnect();
          break;
        }
      }
    }
  });

  return  (
    <div className="flex flex-col">
      {!hide2 ?(
      <button
        onClick={manageRecording}
        className="flex items-center justify-center rounded-full text-token-text-primary focus-visible:outline-black dark:text-white dark:focus-visible:outline-white mb-1 ml-1.5"
        aria-disabled="false"
        disabled ={disabledMicro}
      >
        {!isRecording.current && (
          <svg
            className="icon"
            fill="#000000"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 470 470"
          >
            <g>
              <path
                d="M235,302.296c47.177,0,85.423-38.245,85.423-85.423V85.423C320.423,38.245,282.177,0,235,0s-85.423,38.245-85.423,85.423
                          v131.451C149.577,264.051,187.823,302.296,235,302.296z"
              />
              <path
                d="M350.423,136.148v30h15v50.726c0,71.915-58.508,130.423-130.423,130.423s-130.423-58.507-130.423-130.423v-50.726h15v-30
                          h-45v80.726C74.577,300.273,138.551,369,220,376.589V440h-90.444v30h210.889v-30H250v-63.411
                          c81.449-7.589,145.423-76.317,145.423-159.716v-80.726H350.423z"
              />
            </g>
          </svg>
        )}
        {isRecording.current && (
          <svg
            fill="#000000"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 45.187 45.188"
          >
            <g>
              <g>
                <path d="M0,18.961h4.842V6.052c0-0.446,0.362-0.807,0.807-0.807H18.56V0.404H5.649C2.535,0.404,0,2.939,0,6.053V18.961z" />
                <path
                  d="M39.539,0.403h-12.91v4.841h12.91c0.445,0,0.807,0.362,0.807,0.807v12.91h4.842V6.052
                  C45.189,2.938,42.654,0.403,39.539,0.403z"
                />
                <path
                  d="M4.842,39.136V26.225H0v12.911c0,3.113,2.535,5.648,5.649,5.648H18.56v-4.842H5.649
                  C5.204,39.942,4.842,39.58,4.842,39.136z"
                />
                <path
                  d="M40.346,39.136c0,0.446-0.362,0.807-0.807,0.807H26.628v4.842h12.91c3.115,0,5.648-2.536,5.648-5.65V26.225h-4.842
                  L40.346,39.136L40.346,39.136z"
                />
                <circle cx="22.594" cy="22.594" r="6.455" />
              </g>
            </g>
          </svg>
        )}
      </button>
      ) : (
        <div className="flex justify-center p-4">
                        <form
                    onSubmit={conditionChoix ? handleSubmitOui : handleSubmitOui2}
                    className=""
                    type="button"
                  >
                        <button
                        
                        className="bg-black text-white rounded-lg px-2 py-1 border border-black hover:bg-white hover:text-black transition duration-300 mr-2"
                        >
                        Oui
                        </button>
                        </form> 
                         <form
                    onSubmit={conditionChoix ? handleSubmitNon : handleSubmitNon2}
                    className=""
                    type="button"
                  >
                        <button
                        className="bg-white text-black rounded-lg px-2 py-1 border border-black hover:bg-black hover:text-white transition duration-300"
                        >
                        Non
                        </button>
                         </form> 
                  </div>
      )}
    </div>
  );
};

export default OpusRecording;