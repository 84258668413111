import React, { useEffect, useRef } from 'react';
import NoChat from './NoChat';
import ChatRight from './ChatRight';
import ChatLeft from './ChatLeft';
import { FaRedoAlt } from "react-icons/fa";


const ChatContent = ({
    audioChunks,
    all_messages,
    fastMessage, setFastMessage,
    audioPlaying,
    set_all_message,
    residence,
    showFast,
    questions, setQuestions,
    showFastQuestion, setShowFastQuestion
}) => {

    const messagesEndRef = useRef(null);
    useEffect(() => {
        // Défile automatiquement vers le bas lorsque les messages changent
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [all_messages]); // Déclenche le défilement quand `all_messages` est mis à jour
    const handleReload = ()=>{
        if ('caches' in window) {
            caches.keys().then(cacheNames => {
              cacheNames.forEach(cacheName => {
                console.log("cache deleted", cacheName)
                caches.delete(cacheName);
              });
            });
          }
        window.location.reload(true)
  
    }

    const fastMessageFunction = (event, id) => {
        // Empêche le comportement par défaut
        event.preventDefault();
    
        // Récupère la question avec l'ID
        const selectedQuestion = questions.find(question => question.id === id);
    
        if (selectedQuestion) {
            setFastMessage(selectedQuestion.question);

            // Mise à jour de l'état des questions pour marquer la question comme "done"
            setQuestions(prevQuestions => 
                prevQuestions.map(question => 
                    question.id === id ? { ...question, done: true } : question
                )
            );

            setShowFastQuestion(false)
        }
    };
    

    return (
        <>
        
        <FaRedoAlt onClick={handleReload} className="fixed iconeReset h-5 w-5"/>
        <div className="flex-1 overflow-auto">
            <div className="relative h-full">
                <div className="absolute left-0 right-0">
                    <div
                        className="sticky top-0 p-3 mb-1.5 flex items-center justify-between z-10 h-14 font-semibold bg-token-main-surface-primary">
                        <div className="absolute start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2"></div>
                        <div className="flex items-center gap-0 overflow-auto">
                        </div>
                        <div className="flex gap-2 pr-1">
                            <button data-testid="profile-button"
                                className="flex h-10 w-10 items-center justify-center rounded-full hover:bg-token-main-surface-secondary focus-visible:bg-token-main-surface-secondary focus-visible:outline-0"
                                type="button" id="radix-:rpv:" aria-haspopup="menu" aria-expanded="false" data-state="closed">
                                <div className="flex items-center justify-center overflow-hidden rounded-full">
                                
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                {(audioChunks.length === 0 && all_messages.length === 0 && !audioPlaying) && (
                    <NoChat fastMessage={fastMessage} setFastMessage={setFastMessage} residence={residence} showFast={showFast} />
                )}
                {/* Conditionally render ChatRight and ChatLeft based on the index */}
                {all_messages?.map((message, index) => (
                    index % 2 === 0 ? 
                    (
                    <ChatRight key={index} data={message} />
                    ) : (
                    <ChatLeft key={index} data={message} />
                    )

                ))}
                {showFastQuestion && (
                 <div className="mx-3 mt-12 mb-48 flex max-w-3xl flex-wrap items-stretch justify-center gap-4">
                    
                        <>
                        {questions.filter(question => !question.done).map(filteredQuestion => (
                            <button
                            key={filteredQuestion.id}
                            onClick={(event) => fastMessageFunction(event, filteredQuestion.id)}
                            className="relative w-40 gap-2 rounded-2xl border border-token-border-light px-3 pb-4 pt-3 text-start align-top text-[15px] shadow-xxs transition enabled:hover:bg-token-main-surface-secondary disabled:cursor-not-allowed"
                            >
                            <div className="max-w-full text-balance text-center text-gray-600 dark:text-gray-500 break-word">
                                {filteredQuestion.question}
                            </div>
                            </button>
                        ))}
                        </>
                    
                    </div>
                )}

                {/* L'ancre pour le défilement automatique */}
                <div ref={messagesEndRef} />
            </div>
        </div>
        </>
    );
}

export default ChatContent;
